
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationBitbucketAuthorization'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  loading = false
  error = ''
  bitbucketAuthorizationId = ''
  workspaces: { id: string, name: string }[] | null = null

  get code () {
    return this.$queryValue('code')
  }

  get state () {
    return decodeURIComponent(this.$queryValue('state') || '')
  }

  get organizationId () {
    return this.state?.split('_')[0]
  }

  get redirectPath () {
    return this.state?.split('_')[1] || '/'
  }

  mounted () {
    analytics.organizationBitbucketAuthenticationScreen.track(this, {
      organizationId: [this.organizationId]
    })

    this.error = this.$queryValue('error') || ''
    if (!this.error) {
      this.auth()
    }
  }

  async auth () {
    try {
      if (this.loading) {
        return
      }
      this.loading = true

      if (!this.code) {
        throw new Error('Bitbucket code was not found')
      }
      if (!this.organizationId) {
        throw new Error('Organization id was not found')
      }

      const bitbucketAuthorization = await this.organizationModule.bitbucketAuthorizationCreate({
        code: this.code,
        organizationId: this.organizationId
      })

      this.bitbucketAuthorizationId = bitbucketAuthorization.id

      analytics.organizationBitbucketAuthorizationCreate.track(this, {
        organizationId: [this.organizationId]
      })

      this.workspaces = await this.organizationModule.bitbucketAuthorizationAvailableWorkspaces({
        bitbucketAuthorizationId: this.bitbucketAuthorizationId,
        organizationId: this.organizationId
      })
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    } finally {
      this.loading = false
    }
  }

  async updateWorkspace ({ id, name }: { id: string, name: string }) {
    try {
      if (this.loading) {
        return
      }
      this.loading = true

      await this.organizationModule.bitbucketAuthorizationUpdate({
        bitbucketAuthorizationId: this.bitbucketAuthorizationId,
        organizationId: this.organizationId,
        update: {
          workspaceId: id
        }
      })

      analytics.organizationBitbucketAuthorizationUpdate.track(this, {
        organizationBitbucketWorkspaceName: name,
        organizationId: [this.organizationId]
      })

      this.$router.push(this.redirectPath)
    } catch (err: any) {
      this.error = err.body?.message || err.message
      throw err
    }
  }
}
